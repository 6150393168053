import React, { useState, useEffect } from "react"
import Layout from "../components/layouts/layoutHome"
import { Container, Row, Col, Button } from "react-bootstrap";
import SEO from "../components/seo";
import { navigate, Link } from "gatsby";
import { globalHistory } from "@reach/router";

import carroNuevo from "../images/nuevo-carro.png";
import btnVendeTuAuto from "../images/Nuevo/Principal/b_vende_nissan.png";
import btnVerSemis from "../images/Nuevo/Principal/b_ver_seminuevos_nissan.png";
import todosSomos from "../images/Nuevo/Principal/todos_primos_nissan.jpg";
import tallerMovil from "../images/Nuevo/Principal/tira_taller_movil_nissan.png";
import btnHacerCita from "../images/Nuevo/Principal/b_cita_nissan.png";
import btnNissan from "../images/Nuevo/Principal/b_nissan.png";


import Image from "../components/widgets/basico/image";
import ImageMovil from "../components/widgets/basico/imageMovil";

///////////////////// HOME PAGE //////////////////////

const IndexPage = (props) => {
  const parameters = props.location.search;
  const [aleaMovil, setAleaMovil] = useState();

  function navegante(marca) {
    globalHistory.location.pathname.split("");
    navigate(`/${marca.toLowerCase()}/catalogo/nuevos` + parameters, { state: { marcaHome: marca } });
  }

  useEffect(() => {
    const numberBannerMovil = [2];
    var aletatorioMovil = parseInt(Math.random() * (numberBannerMovil.length));
    var aleatorioFondo = `f-movil-1.jpg`;
    console.log(aleatorioFondo, "FONDO M");
    setAleaMovil(aleatorioFondo);
  }, []);

  //console.log("Aleatorio de nissan: ", aleaMovil);
  return (
    <Layout parameters={parameters}>
      <SEO
        title="Rivero Nissan Monterrey - Agencia Autos Nuevos y Seminuevos"
        description="Grupo Automotriz - Agencias Nissan Monterrey. Apoyamos a la gente de manera transparente, para que estrenes tu carro nuevo o seminuevo. March, Versa, Sentra, Kicks, Xtrail, Altima"
        keywords="Rivero Nissan, agencia nissan, autos seminuevos, carro nuevo, carro nissan, estrena carro"
      />
      <div className="fullContain">
        <Container fluid className="d-lg-block d-md-block d-none p-0" >
   
          <div className="d-lg-flex d-md-flex d-none" style={{ position: "relative" }} >
              <div  style={{height: '100%', width: '100%' }}>
                  <Image
                      fileName={`fondo-nissan-2.jpg`}
                      alt="galeria video silverado"
                      imgStyles={{ width: "100%", height: "100vh" }}
                      style={{ width: "100%", height: "100vh" }}
              /> 
              </div>
            <div className="containerDesktop"> 
                <Container className="pt-5">
                    <div style={{height: 75}} className="pt-3">

{/*                       <Image
                          fileName={`todos_primos_nissan`}
                          alt="seminuevos"
                          imgStyles={{ width: "50%", height: 70, objectFit: 'contain'}}
                          style={{ width: "50%", height: 70}}
                      /> */}
                    </div>
                    </Container>
                    <Container>
                    <div>
                      {<>
                        <Row>
                          <h1 className="slogan">¡HOY ES EL DÍA DE</h1>
                          <h1 className="slogan">ESTRENAR TU</h1>
                          <h1 className="slogan">NUEVA KICKS!</h1>
                        </Row>
                        <Row>
                        <Col lg={6} md={6} sm={6} xs={6} >
                          <div className="mensualidadDesde">
                            Mensualidad desde : $3,999
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6}>
                          <Link id="home-button-servicio" to={"/nissan/catalogo/nuevos/" + parameters}>
                            <div style={{position: 'relative', display: 'flex', zIndex: 1, alignItems: 'center', justifyContent: 'center'}} className="bannerTiraSeminuevos">
                                <Image
                                    fileName={`img_b_nuevos_nissan`}
                                    alt="home boton nuevos nissan"
                                    imgStyles={{ width: "100%", height: "100%", objectFit: 'contain'}}
                                    style={{ width: "100%", height: "100%" }}
                                />
                            </div>
                          </Link>
                        </Col>
                      </Row></>}
                      <Row className="mt-3 mb-4">
                        <Col lg={6} md={6} sm={6} xs={6}>
                          <Link id="home-button-servicio" to={"/catalogo/seminuevos/" + parameters}>
                            <div style={{position: 'relative', display: 'flex', zIndex: 1, alignItems: 'center', justifyContent: 'center'}} className="bannerTiraSeminuevos">
                                <Image
                                    fileName={`ver-semi_2`}
                                    alt="home boton seminuevos nissan"
                                    imgStyles={{ width: "100%", height: "100%", objectFit: 'contain'}}
                                    style={{ width: "100%", height: "100%" }}
                                />
                            </div>
                          </Link>
                        </Col>

                        <Col lg={6} md={6} sm={6} xs={6}>
                          <Link id="home-button-servicio" to={"/taller-de-servicio/" + parameters}>
                              <div style={{position: 'relative', display: 'flex', zIndex: 1, alignItems: 'center'}} className="bannerTiraTallerServicio">
                                  <Image
                                      fileName={`img_b_taller_nissan`}
                                      alt="home boton taller servicio nissan"
                                      imgStyles={{ width: "100%", height: "100%", objectFit: 'contain'}}
                                      style={{ width: "100%", height: "100%" }}
                                  />
                              </div>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                </Container>
            </div>
          </div>
        </Container>

        <Container className="d-lg-none d-md-none d-flex px-0">
          <div className="containerZoomMovil">
            <div className="container-zoom-movil">
              {<Image
                fileName={`${aleaMovil}`}
                alt="banner Image Home"
                style={{ height: '100%' }}
              />}
            </div>
          </div>
          <Container className="containerMovil">
{/*             <ImageMovil
                fileName={`todos_primos_nissan`}
                alt="home todos somos primos rivero"
                imgStyles={{ width: 150, objectFit: 'contain'}}
                style={{ width: 150}}
            /> */}

          <div>
              <Row>
                <h1 className="slogan-movil">¡HOY ES EL DÍA DE</h1>
                <h1 className="slogan-movil">ESTRENAR TU</h1>
                <h1 className="slogan-movil">NUEVA KICKS!</h1>
              </Row>
              <Row className="pt-1 pb-1">
                <div className="mensualidadDesde">Mensualidad desde : $3,999</div>
              </Row>
          </div>
          </Container>
          
        </Container>
        <Container id="containerTirasMovil" className="d-lg-none d-md-none d-block">
              <Row  className="p-3 div-tira-nuevos-nissan" style={{background: 'linear-gradient(0deg, rgba(0,0,0,1) 35%, rgba(255,255,255,0) 100%)', marginBottom: -1}}>
                <Col lg={6} md={6} sm={6} xs={6} >
                  <div className="tira-seminuevos-movil">
                    <div className="semisMovil">
                    <p className="m-0" style={{fontFamily: 'Narrow', color: "white", fontSize: '1em'}}>¡ESTRENA UN AUTO</p>
                    <p className="mb-0" style={{fontFamily: 'Narrow', color: "white", fontSize: '2em', marginTop: '-10px'}}>NUEVO!</p>
              {/*     <Image
                        fileName="carronuevo.png"
                        alt="Banner Seminuevos"
                        imgStyles={{ width: 120, objectFit: 'contain'}}
                        style={{ width: 120}}
                      /> */}
                    </div>
                  </div>
                </Col>
                <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} lg={6} md={6} sm={6} xs={6}>
                  <center>
                    <div className="tira-seminuevos-movil">
                      <Link id="btnVerAutosMovil" to={"/nissan/catalogo/nuevos/" + parameters}>
                        <div className="btn-tira-movil m-1">
                          <Image
                            fileName="b_ver_seminuevos_nissan"
                            alt="Banner Seminuevos"
                            imgStyles={{ width: 120, objectFit: 'contain'}}
                            style={{ width: 120}}
                          />
                        </div>
                      </Link>
                    </div>
                  </center>
                </Col>
              </Row>
              <Row  className="p-3 div-tira-seminuevos-nissan">
                <Col lg={6} md={6} sm={6} xs={6} >
                  <div className="tira-seminuevos-movil">
                    <div className="semisMovil">
                    <p className="m-0" style={{fontFamily: 'Narrow', color: "white", fontSize: '1em'}}>¿BUSCAS UN CARRO</p>
                    <p className="mb-0" style={{fontFamily: 'Narrow', color: "white", fontSize: '2em', marginTop: '-10px'}}>SEMINUEVO?</p>
                    {/* <Image
                        fileName="seminuevo.png"
                        alt="Banner Seminuevos"
                        imgStyles={{ width: 170, objectFit: 'contain'}}
                        style={{ width: 170}}
                      /> */}
                    </div>
                  </div>
                </Col>
                <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} lg={6} md={6} sm={6} xs={6}>
                  <center>
                    <div className="tira-seminuevos-movil">
                      <Link id="btnVerAutosMovil" to={"/catalogo/seminuevos/" + parameters}>
                        <div className="btn-tira-movil m-1">
                          <Image
                            fileName="ver-semi.png"
                            alt="Banner Seminuevos"
                            imgStyles={{ width: 120, objectFit: 'contain'}}
                            style={{ width: 120}}
                          />
                        </div>
                      </Link>
                    </div>
                  </center>
                </Col>
              </Row>
              <Row  className="p-3 div-tira-taller-nissan"> 
                <Col lg={6} md={6} sm={6} xs={6} >
                  <div className="tira-seminuevos-movil">
                    <div className="semisMovil">
                    <p className="m-0" style={{fontFamily: 'Narrow', color: "white", fontSize: '1em'}}>AGENDA TU CITA DE</p>
                    <p className="mb-0" style={{fontFamily: 'Narrow', color: "white", fontSize: '2em', marginTop: '-10px'}}>SERVICIO</p>
                    {/* <Image
                        fileName="taller.png"
                        alt="Banner Seminuevos"
                        imgStyles={{ width: 140, objectFit: 'contain'}}
                        style={{ width: 140}}
                      /> */}
                    </div>
                  </div>
                </Col>
                <Col className="d-flex align-items-center justify-content-center" lg={6} md={6} sm={6} xs={6}>
                  <center>
                    <div className="tira-seminuevos-movil">
                      <Link id="btnVerAutosMovil" to={"/taller-de-servicio/" + parameters}>
                        <div className="btn-tira-movil m-1">
                          <Image
                            fileName="b_cita_nissan"
                            alt="Banner Seminuevos"
                            imgStyles={{ width: 120, objectFit: 'contain'}}
                            style={{ width: 120}}
                          />
                        </div>
                      </Link>
                    </div>
                  </center>
                </Col>
              </Row>


      { /* <Container className="d-lg-none d-md-none d-block dosTiras">
          <Row  className="p-3 div-tira-seminuevos-nissan">
            <Col className="d-flex align-items-center p-0" lg={6} md={6} sm={6} xs={6} >
              <div className="tira-seminuevos-movil">
                <div className="semisMovil">
                  <Image
                    fileName="tira_seminuevos_movil.png"
                    alt="Banner Seminuevos"
                  />
                </div>
              </div>
            </Col>
            <Col className="d-flex align-items-center justify-content-center" lg={6} md={6} sm={6} xs={6}>
              <center>
                <div className="tira-seminuevos-movil">
                  <Link id="btnVerAutosMovil" to={"/catalogo/seminuevos/" + parameters}>
                    <div className="btn-tira-movil m-1">
                      <Image
                        fileName="b_ver_seminuevos_nissan.png"
                        alt="Banner Seminuevos"
                      />
                    </div>
                  </Link>
                </div>
              </center>
            </Col>
          </Row>
          <Row  className="p-3 div-tira-taller-nissan">
            <Col className="d-flex align-items-center p-0" lg={6} md={6} sm={6} xs={6} >
              <div id="btnHacerCitaMovil" className="tira-taller-movil">
                <img className="tallerMovil" src={tallerMovil} />
              </div>
            </Col>

            <Col className="d-flex justify-content-center align-items-center" lg={6} md={6} sm={6} xs={6} >
              <div id="btnHacerCita" className="tira-taller-movil">
                <Link to={"/taller-de-servicio/" + parameters}>
                  <img className="btn-tira-movil m-1" src={btnHacerCita} />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>*/}
        </Container>
      </div>
    </Layout>
  );
}

export default IndexPage
